import { createRouter, createWebHistory } from 'vue-router';

const routes = [{
    path: '/',
    redirect: '/login',
    component: () =>
        import('../views/HomePage.vue'), // 替换成你的主页组件
    meta: {
        title: '主页'
    }
},
{
    path: '/guide',
    component: () =>
        import('../views/GuidePage.vue'), // 替换成你的主页组件
    meta: {
        title: '印联云打印管理端'
    }
},
{
    path: '/login',
    component: () =>
        import('../views/LoginPage.vue'), // 替换成你的主页组件
    meta: {
        title: '印联云打印管理端'
    }
},
{
    path: '/print',
    component: () =>
        import('../views/PrintPage.vue'),
    meta: { title: '本地文件' },
    beforeEnter: (to, from, next) => {
        // 解码路由参数，并将其作为 props 传递给组件
        to.params.data1 = decodeURIComponent(to.query.data1 || '');
        to.params.data2 = decodeURIComponent(to.query.data2 || '');
        to.params.fileId = decodeURIComponent(to.query.fileId || '');
        to.params.auto = decodeURIComponent(to.query.auto || '');
        next();
    },
},
{
    path: '/manage/store',
    component: () =>
        import('../views/StorePage.vue'), // 替换成你的关于页面组件
    meta: {
        title: '门店管理'
    },
},
{
    path: '/manage/store/store_detail',
    component: () =>
        import('../views/StoreDetail.vue'),
    meta: {
        title: '门店详情'
    }
},
{
    path: '/h5/auto_print',
    component: () =>
        import('../views/AutoPrint.vue'),
    meta: {
        title: '自助打印'
    }
},
{
    path: '/h5/service',
    name: 'servicepage',
    component: () =>
        import('../views/ServicePage.vue'), // 替换成你的关于页面组件
    meta: {
        title: '在线客服'
    },
    beforeEnter: (to, from, next) => {
        // 解码路由参数，并将其作为 props 传递给组件
        to.params.data1 = decodeURIComponent(to.query.data1 || '');
        to.params.data2 = decodeURIComponent(to.query.data2 || '');
        to.params.data3 = decodeURIComponent(to.query.data3 || '');
        to.params.data4 = decodeURIComponent(to.query.data4 || '');
        next();
    },
},
{
    path: '/h5/service_list',
    component: () =>
        import('../views/ServerList.vue'), // 替换成你的关于页面组件
    meta: {
        title: '顾客列表'
    },
    beforeEnter: (to, from, next) => {
        // 解码路由参数，并将其作为 props 传递给组件
        to.params.data1 = decodeURIComponent(to.query.data1 || '');
        to.params.data2 = decodeURIComponent(to.query.data2 || '');
        to.params.data3 = decodeURIComponent(to.query.data3 || '');
        next();
    },
},
{
    path: '/h5/service_login',
    component: () =>
        import('../views/ServicePages/ServiceLogin.vue'),
    meta: {
        title: '客服登陆'
    }
},
{
    path: '/h5/service_register',
    component: () =>
        import('../views/ServicePages/ServiceRegister.vue'),
    meta: {
        title: '客服注册'
    }
},
{
    path: '/h5/service_work',
    component: () =>
        import('../views/ServicePages/ServiceWork.vue'),
    meta: {
        title: '客户咨询'
    }
},
{
    path: '/h5/accept_template',
    component: () =>
        import('../views/AcceptTemplate.vue'),
    meta: {
        title: '授权页面'
    }
},
{
    path: '/h5/user_agree',
    component: () =>
        import('@/components/mobile/UserAgree.vue'),
    meta: { title: '用户协议' },
    beforeEnter: (to, from, next) => {
        // 解码路由参数，并将其作为 props 传递给组件
        to.params.data1 = decodeURIComponent(to.query.data1 || '');
        to.params.data2 = decodeURIComponent(to.query.data2 || '');
        next();
    },
},
{
    path: '/mobile/store_status',
    component: () =>
        import('@/components/mobile/StoreStatus.vue'),
    meta: {
        title: '门店状态'
    }
},
{
    path: '/mobile/money_manage',
    component: () =>
        import('@/components/mobile/MoneyManage.vue'),
    meta: {
        title: '资金管理'
    }
},
{
    path: '/mobile/new_order',
    component: () =>
        import('@/components/mobile/NewOrder.vue'),
    meta: {
        title: '新订单'
    }
},
{
    path: '/mobile/wait_order',
    component: () =>
        import('@/components/mobile/WaitOrder.vue'),
    meta: {
        title: '待处理订单'
    }
},
{
    path: '/mobile/service',
    component: () =>
        import('@/components/mobile/ServicePage.vue'),
    meta: {
        title: '官方客服'
    }
},
{
    path: '/mobile/questions',
    component: () =>
        import('@/components/mobile/NormalQs.vue'),
    meta: {
        title: '常见问题'
    }
},
{
    path: '/mobile/order_map',
    component: () =>
        import('@/components/mobile/OrderMap.vue'),
    meta: {
        title: '订单列表'
    }
},
{
    path: '/mobile/store_feedback',
    component: () =>
        import('@/components/mobile/StoreFeedback.vue'),
    meta: {
        title: '问题反馈'
    }
},
{
    path: '/mobile/register',
    component: () =>
        import('@/components/mobile/RegisterPage.vue'),
    meta: {
        title: '门店注册'
    }
},
{
    path: '/mobile/mappage',
    component: () =>
        import('@/components/mobile/MapPage.vue'),
    meta: {
        title: '地图选点'
    }
},
{
    path: '/mobile/registerGuide',
    component: () =>
        import('@/components/mobile/RegisterGuide.vue'),
    meta: {
        title: '门店入驻'
    }
},
    // {
    //     path: '/user/:id', // 动态路由示例
    //     component: () =>
    //         import ('../views/UserPage.vue'), // 替换成你的用户页面组件
    //     meta: {
    //         title: ''
    //     }
    // },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: () => ({ y: 0 }), // 确保在重定向后滚动到页面顶部
});

// 移动设备检测函数
function isMobileDevice() {
    const userAgent = navigator.userAgent;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    return isMobile;
}

// 避免无限重定向的标志
let isRedirecting = false;


router.beforeEach((to, from, next) => {
    // 检查是否是移动设备

    const check = to.path.split('/')[1]

    if (isMobileDevice() && check != 'print') {
        // 如果是移动设备，并且当前不是在引导页
        if (check == 'mobile') {
            if (to.path.split('/')[2] === '/mobile/order_map') {
                next('/mobile/order_map');
                isRedirecting = true;
            } else {
                next(); // 如果已经在引导页，继续
                isRedirecting = true;
            }

        } else if (check == 'h5') {
            next(); // 如果已经在引导页，继续
            isRedirecting = true;
        } else if (to.path !== '/guide') {
            console.log('非引导页跳转');
            next('/guide'); // 尝试重定向到引导页
            isRedirecting = true;

        } else {
            next(); // 如果已经在引导页，继续
            isRedirecting = true;
        }
    } else {
        // 如果不是移动设备，继续原定路由
        next();
        isRedirecting = true;
    }
});

// 重置重定向标志
router.afterEach(() => {
    isRedirecting = false;
});

export default router;